import React from 'react';
import './App.css';
import Particles from "react-tsparticles";
import {Config} from "./config/Config";
import {QuoteText} from "./components/QuoteText";
import {TextBlock} from "./components/TextBlock";
import {Name} from "./components/Name";
import {Menu} from "./components/Menu";
import {Contacts} from "./components/Contacts";

class App extends React.Component<{}> {
    readonly onResize: () => void;

    constructor() {
        super({});

        this.onResize = () => {
            const container = document.getElementById('container');

            if(container) {
                container.style.height = '100%'
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.onResize())
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        window.removeEventListener('resize', this.onResize)
    }

    render() {
        return (
            <div className="App">
                <header className="App-main-text" id="container">
                    <Name name="Evgeny Kovzel" />
                    <Menu />
                    <QuoteText text="THOSE WHO CAN – DO, THOSE WHO CAN'T – TEACH"/>
                    <TextBlock text="Hello, my name is Evgeny."/>
                    <TextBlock text="I have more than 6 years in Web Development. Especially in Back-end Development."/>
                    <TextBlock
                        text="Strong knowledge of back-end technologies such as PHP (Symfony 5/Flex) in conjunction with technologies and instruments such as Elasticsearch, Git, MySQL, RabbitMQ, Docker, Behat, PHPUnit, Jest."/>
                    <TextBlock
                        text="Also have experience with front-end development and technologies such as jQuery, Node.js, ReactJS."/>
                    <TextBlock
                        text="Also have experience setting up and working with AWS resources such as CloudWatch, Lambda, SQS, ECR, ECS, EC2, etc."/>
                    <Contacts/>
                    <Particles
                        id="tsparticles"
                        options={Config}
                    />
                </header>
            </div>
        );
    }
}

export default App;
