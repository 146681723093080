import React from "react";
import './Contacts.css'

export class Contacts extends React.Component {
    render() {
        return (
            <div className="Contacts">
                <p>Contacts:</p>
                <p><a href="mailto:contact@jack-kovzel.com">contact@jack-kovzel.com</a></p>
                <p><a href="https://www.linkedin.com/in/jack-kovzel/">
                    <img className="contact-social"
                         src="https://cdn-icons-png.flaticon.com/512/174/174857.png"></img>
                </a>
                </p>
            </div>
        );
    }
}