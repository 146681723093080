import {ISourceOptions} from "tsparticles";

export const Config = {
    background: {
        color: {
            value: "#0d47a1"
        },
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover",
        opacity: 0.1
    },
    fullScreen: {
        zIndex: 1
    },
    particles: {
        links: {
            distance: 150,
            enable: true,
            color: "34b7eb",
            opacity: 0.5,
        },
        move: {
            enable: true,
            speed: 1,

        },
        size: {
            value: 1
        },
        number: {
            value: 50,
            density: {
                enable: true
            }
        },
        color: {
            value: "#fff"
        },

    }
} as ISourceOptions